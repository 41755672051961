<template>
  <div class="home">
    <!-- 弹框 -->
    <div class="modal_success" v-if="modalFlag == true">填写完成</div>
    <!-- 弹框 -->

    <div v-if="state == 0">
      未获取到随访单、随访单可能已经失效、联系医生重新为你发送随访单
    </div>
    <div class="header" v-if="state != 0">
      - {{ state == 1 ? "患者已填写完毕" : "以下为患者填写部分" }} -
    </div>
    <div class="main" v-if="state != '0'">
      <div v-for="(item, i) in questionsData" :key="i" class="question-content">
        <div class="question-title">
          {{ i + 1 }}、{{ item.questionTitle
          }}<span
            >（{{
              item.type == "sc"
                ? "单选"
                : item.type == "mc"
                ? "多选题"
                : item.type == "qa"
                ? "简答题"
                : "判断题"
            }}）</span
          >
        </div>
        <!-- 单选 多选 判断 -->
        <div
          class="type-sc"
          v-if="item.type == 'sc' || item.type == 'mc' || item.type == 'tf'"
        >
          <div
            v-for="(item1, j) in item.option"
            :style="item.type == 'tf' ? 'width:100%' : ''"
            :key="j"
            @click="selectQuestionAnswer(i, j)"
          >
            <img
              v-if="item.type == 'mc' && item1.isDefault != -1"
              src="https://test-hospital-sass.oss-cn-shanghai.aliyuncs.com/wx-small-public-img/common-true.png"
              alt=""
            />
            <img
              v-if="item.type == 'mc' && item1.isDefault == -1"
              src="https://test-hospital-sass.oss-cn-shanghai.aliyuncs.com/wx-small-public-img/common-false.png"
              alt=""
            />
            <img
              v-if="
                (item.type == 'sc' || item.type == 'tf') &&
                item1.isDefault != -1
              "
              src="https://test-hospital-sass.oss-cn-shanghai.aliyuncs.com/wx-small-public-img/common1-true.png"
              alt=""
            />
            <img
              v-if="
                (item.type == 'sc' || item.type == 'tf') &&
                item1.isDefault == -1
              "
              src="https://test-hospital-sass.oss-cn-shanghai.aliyuncs.com/wx-small-public-img/common1-false.png"
              alt=""
            />
            <!-- <span>{{ item1.optionPrefix }}</span
            >、 -->
            {{ item1.option }}
          </div>
        </div>
        <!-- 单选 多选 判断 -->

        <!-- 简答 -->
        <div class="type-qa" v-if="item.type == 'qa'">
          <textarea
            maxlength="140"
            v-model="item.option[0].answer"
            v-if="state != 1"
          ></textarea>
          <textarea
            maxlength="140"
            v-model="item.option[0].answer"
            v-else
            disabled
          ></textarea>
        </div>
        <!-- 简答 -->

        <!-- 线 -->
        <div class="line"></div>
      </div>

      <!-- 上传图片 -->
      <div class="upload">
        <div class="title">
          请上传舌苔和舌底照片，如患者是儿童可上传三关手纹 照片替代
        </div>
        <div class="upload-img">
          <div class="first-img" v-if="imgList.length < 4 && state != 1">
            <img src="../assets/picture.png" alt="" />
            <div>添加照片</div>
            <input
              type="file"
              class="upload"
              @change="addImg($event)"
              ref="inputer"
              multiple
              accept="image/*"
            />
          </div>
          <div class="other-img" v-for="(item, i) in imgList" :key="i">
            <img class="img" :src="item.link" alt="" />
            <img
              v-if="state != 1"
              @click="deleteImageIndexList(i)"
              class="delete-img"
              src="https://test-hospital-sass.oss-cn-shanghai.aliyuncs.com/wx-small-public-img/medelete.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <!-- 上传图片 -->
    </div>

    <div class="space16"></div>
    <!-- 本次诊疗体验 -->
    <div class="footer" v-if="state != '0'">
      <div class="title">本次诊疗体验</div>
      <div class="four-pic">
        <div
          :class="fourSelectIndex == 4 ? 'select-pic' : ''"
          @click="clickSelectFace(4)"
        >
          <img
            v-if="fourSelectIndex == 4"
            src="../../src/assets/face/face-1-active.png"
            alt=""
          />
          <img v-else src="../../src/assets/face/face-1.png" alt="" />
          <p>非常满意</p>
        </div>
        <div
          :class="fourSelectIndex == 3 ? 'select-pic' : ''"
          @click="clickSelectFace(3)"
        >
          <img
            v-if="fourSelectIndex == 3"
            src="../../src/assets/face/face-2-active.png"
            alt=""
          />
          <img v-else src="../../src/assets/face/face-2.png" alt="" />
          <p>满意</p>
        </div>
        <div
          :class="fourSelectIndex == 2 ? 'select-pic' : ''"
          @click="clickSelectFace(2)"
        >
          <img
            v-if="fourSelectIndex == 2"
            src="../../src/assets/face/face-3-active.png"
            alt=""
          />
          <img v-else src="../../src/assets/face/face-3.png" alt="" />
          <p>一般</p>
        </div>
        <div
          :class="fourSelectIndex == 1 ? 'select-pic' : ''"
          @click="clickSelectFace(1)"
        >
          <img
            v-if="fourSelectIndex == 1"
            src="../../src/assets/face/face-4-active.png"
            alt=""
          />
          <img v-else src="../../src/assets/face/face-4.png" alt="" />
          <p>需要改善</p>
        </div>
      </div>
    </div>
    <!-- 本次诊疗体验 -->
    <!-- 提交用户的表单 -->
    <div v-if="state != '0'">
      <div class="submit-from" v-if="state == -1" @click="submitQuestion">
        确认提交
      </div>
      <div class="submit-from" v-else>已填写</div>
    </div>
    <!-- 提交用户的表单 -->
    <div class="space100"></div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Home",
  data() {
    return {
      // 1341589642109005829
      formId: "",
      state: 0, // 填写状态 -1 未填写   1 已填写，不能让用户再次提交
      formData: new FormData(),
      patientGetFollowData: {},
      questionsData: [], // 题目数据
      fourSelectIndex: 0, // 笑脸选中项
      imgList: [], // 图片列表
      modalFlag: false, // 弹框显示
    };
  },
  mounted() {
    if (this.$route.query.id) {
      this.formId = this.$route.query.id;
      this.state = -1;
    }
    // 请求随访单
    this.getPatientFollowup();
  },
  methods: {
    /**
     * 请求随访单
     */
    getPatientFollowup() {
      var params = {
        id: this.formId,
      };
      let config = {
        Headers: {
          Origin: "https://api.test.songguoai.com",
          Host: "api.test.songguoai.com",
        },
      };
      this.$axios
        .get(
          "/bh-general/h5/patient-get-followup?id=" + params.id,
          params,
          config
        )
        .then((res) => {
          if (res.data.data.state == 1) {
            this.state = res.data.data.state; // 状态
            this.fourSelectIndex = res.data.data.grade; // 分数
            this.patientGetFollowData = res.data.data;
            var questionsDataa = res.data.data.questionAndOption; // 问题 答案部署

            // 图片部署
            var imgList = [];
            var uploadImg = res.data.data.uploadImg.split(",");
            for (var p = 0; p < uploadImg.length; p++) {
              imgList.push({
                link: uploadImg[p],
              });
            }
            this.imgList = imgList;
            // 图片部署
            for (var k = 0; k < questionsDataa.length; k++) {
              questionsDataa[k].option = JSON.parse(
                questionsDataa[k].questionAnswerOption
              );
              // 答案部署
              if (questionsDataa[k].type == "qa") {
                questionsDataa[k].option[0].answer = questionsDataa[k].answer;
              } else {
                var answer = questionsDataa[k].answer.split(",");
                for (var h = 0; h < questionsDataa[k].option.length; h++) {
                  if (answer.indexOf(questionsDataa[k].option[h].prefix) > -1) {
                    questionsDataa[k].option[h].isDefault = 1;
                  } else {
                    questionsDataa[k].option[h].isDefault = -1;
                  }
                }
              }
              // 答案部署
            }
            this.questionsData = questionsDataa;
          } else {
            this.patientGetFollowData = res.data.data;
            var questionsData = res.data.data.questionAndOption;
            for (var i = 0; i < questionsData.length; i++) {
              for (var j = 0; j < questionsData[i].option.length; j++) {
                questionsData[i].option[j].isDefault = -1;
                questionsData[i].option[j].option =
                  questionsData[i].option[j].optionDescription;
              }
            }
            this.questionsData = questionsData;
          }
        });
    },
    /**
     * 选择答案
     */
    selectQuestionAnswer(i, j) {
      if (this.state == 1) {
        return;
      }
      if (
        this.questionsData[i].type == "sc" ||
        this.questionsData[i].type == "tf"
      ) {
        for (var ii = 0; ii < this.questionsData[i].option.length; ii++) {
          this.questionsData[i].option[ii].isDefault = -1;
        }
        this.questionsData[i].option[j].isDefault = 1;
      }
      if (this.questionsData[i].type == "mc") {
        this.questionsData[i].option[j].isDefault =
          this.questionsData[i].option[j].isDefault == 1 ? -1 : 1;
      }
      this.$forceUpdate(); // 解决多层渲染 vue渲染慢 或者 直接不渲染的bug
    },
    /**
     * 选择照片
     */
    addImg(event) {
      let file = event.target.files; //target事件
      // let size = file[0].size;//获取文件大小
      this.uploadImg(file[0]);
    },
    /**
     * 上传照片
     */
    uploadImg(img) {
      if (this.state == 1) {
        return;
      }
      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
          // contentType: "application/json",
        },
      };

      var formData = new FormData();
      // formData.append("userId", ""); // 用户的id
      // formData.append("projectName", ""); //项目名
      // formData.append("folderName", "imgs/trave"); //标识要给他传到那个文件夹中
      // formData.append("waterTypes", "0");
      // formData.append("businessType", trave) ** //这个是我项目中必传的
      formData.append("file", img) ** //最重要的就是这一步，要给后台传的文件
        this.$axios
          .post("/blade-resource/oss/endpoint/put-file", formData, config)
          .then((res) => {
            if (res.data.code == 200) {
              this.imgList.push(res.data.data);
            }
          });
    },
    /**
     * 删除照片
     */
    deleteImageIndexList(i) {
      this.imgList.splice(i, 1);
    },
    /**
     * 选择笑脸
     */
    clickSelectFace(index) {
      if (this.state == 1) {
        return;
      }
      this.fourSelectIndex = index;
    },
    /**
     * 提交答案
     */
    submitQuestion() {
      var questionAndAnswer = [];
      for (var i = 0; i < this.questionsData.length; i++) {
        var data = {
          questionId: this.questionsData[i].id,
          answer: undefined,
        };
        if (
          this.questionsData[i].type == "sc" ||
          this.questionsData[i].type == "mc" ||
          this.questionsData[i].type == "tf"
        ) {
          var answer = [];
          for (var j = 0; j < this.questionsData[i].option.length; j++) {
            if (this.questionsData[i].option[j].isDefault == 1) {
              answer.push(this.questionsData[i].option[j].optionPrefix);
            }
          }
          data.answer = answer.join(",");
        } else {
          data.answer = this.questionsData[i].option[0].answer;
        }
        questionAndAnswer.push(data);
      }
      var uploadImg = [];
      for (var o = 0; o < this.imgList.length; o++) {
        uploadImg.push(this.imgList[o].link);
      }
      var params = {
        id: this.formId,
        uploadImg: uploadImg.join(","),
        grade: this.fourSelectIndex, // 分数
        questionAndAnswer: questionAndAnswer,
        state: 1,
      };
      this.$axios
        .post("/bh-general/h5/patient-write-followup", params)
        .then(() => {
          this.modalFlag = true;
          this.getPatientFollowup();
          setTimeout(() => {
            this.modalFlag = false;
          }, 3000);
        });
    },
  },
};
</script>

<style lang="less">
.home {
  width: 100%;
  max-width: 7.5rem;
  margin: 0 auto;
  min-height: 100vh;
  background: white;
  // 弹框
  .modal_success {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 1.5rem;
    height: 0.6rem;
    background: rgba(0, 0, 0, 0.7);
    // background: red;
    font-size: 0.28rem;
    border-radius: 0.2rem;
    color: white;
    line-height: 0.6rem;
    text-align: center;
  }
  // 弹框
  .space16 {
    width: 100%;
    height: 0.16rem;
    background: #f7f7f7;
  }
  .space100 {
    width: 100%;
    height: 1rem;
    background: #ffffff;
  }
  .header {
    width: 100%;
    height: 0.96rem;
    background: #f7f7f7;
    line-height: 0.96rem;
    text-align: center;
  }
  .main {
    width: 100%;
    background: white;
    .question-content {
      width: 100%;
      padding: 0.32rem 0;
      .question-title {
        text-align: left;
        width: 100%;
        font-size: 0.32rem;
        color: #282828;
        line-height: 0.44rem;
        font-weight: bold;
        padding-left: 0.32rem;
        span {
          color: gray;
          padding-left: 0.2rem;
        }
      }
      // 单选 多选 判断
      .type-sc {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        > div {
          width: 50%;
          text-align: left;
          font-size: 0.28rem;
          line-height: 0.4rem;
          padding: 0.16rem 0 0.16rem 0.48rem;
          display: flex;
          justify-content: left;
          align-items: center;
          img {
            width: 0.34rem;
            height: 0.34rem;
            margin-right: 0.14rem;
          }
          span {
            font-weight: bold;
          }
        }
      }
      // 单选 多选 判断
      // 简答题
      .type-qa {
        width: 100%;
        textarea {
          width: 92.3%;
          min-height: 2.18rem;
          margin: 0 auto;
          background: #f3f3f3;
          margin-top: 0.22rem;
          padding: 0.22rem 0.28rem;
        }
      }
      // 简答题
      .line {
        width: 92%;
        height: 0.2rem;
        border-bottom: 0.02rem dashed #d7d7d7;
        margin: 0 auto;
      }
    }

    // 上传
    .upload {
      width: 92.3%;
      margin: 0 auto;
      text-align: left;
      .title {
        color: #7e7e7e;
        font-size: 0.28rem;
      }
      .upload-img {
        width: 100%;
        display: flex;
        justify-content: left;
        flex-wrap: wrap;
        padding: 0.5rem 0.36rem;
        > div {
          width: 1.94rem;
          height: 1.94rem;
          border-radius: 0.06rem;
          margin: 0 0.1rem 0.1rem 0;
          position: relative;
          .img {
            width: 1.94rem;
            height: 1.94rem;
            border-radius: 0.06rem;
          }
          .delete-img {
            position: absolute;
            width: 0.5rem;
            height: 0.5rem;
            right: -0.2rem;
            top: -0.2rem;
            z-index: 10;
          }
        }
        .first-img {
          width: 1.94rem;
          height: 1.94rem;
          text-align: center;
          font-size: 0.24rem;
          color: #afafaf;
          position: relative;
          border: 0.01rem dashed #979797;
          img {
            width: 0.48rem;
            height: 0.48rem;
            margin-top: 0.54rem;
          }
          input {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 1.94rem;
            height: 1.94rem;
            opacity: 0;
          }
        }
      }
    }
    // 上传
  }

  .footer {
    width: 100%;
    background: white;
    .title {
      width: 100%;
      line-height: 0.96rem;
      background: white;
      font-size: 0.28rem;
    }
    .four-pic {
      width: 100%;
      display: flex;
      justify-content: space-around;
      color: #898989;
      .select-pic {
        color: #ff734d;
      }
      img {
        width: 0.72rem;
        height: 0.72rem;
      }
      p {
        padding-top: 0.26rem;
      }
    }
  }
  .submit-from {
    width: 7.1rem;
    height: 0.92rem;
    margin: 0 auto;
    background: #ff734d;
    border-radius: 0.48rem;
    color: #ffffff;
    font-size: 0.32rem;
    line-height: 0.92rem;
    text-align: center;
    margin-top: 0.62rem;
  }
}
</style>
