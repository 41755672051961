import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import { baseURL } from './utils/config.js';

import axios from 'axios';
axios.defaults.baseURL = baseURL;
Vue.prototype.$axios = axios;

// let docEl = document.documentElement;
// export function getSize() {
//   docEl.style.fontSize = screenWidth / (750 / 40) + 'px';
// }
// window.addEventListener('resize', () => {
//   getSize();
// });


Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
