/**
 * 请求数据配置
 * 配置编译环境和线上环境之间的IP切换
 *
 * baseURL: 域名地址
 **/
// http://api.test.songzizhongyi.com:9998/api/bh-general/h5/patient-get-followup?id=1341589642109005829
var baseURL = "";
// if (process.env.NODE_ENV === "development") {
//     baseURL = "http://api.test.songzizhongyi.com:9998/api";
// } else if (process.env.NODE_ENV === "production") {
//     baseURL = "http://api.test.songzizhongyi.com:9998/api";
// } else if (process.env.NODE_ENV === "test") {
//     baseURL = "http://api.test.songzizhongyi.com:9998/api";
// }
console.log(window.location.href.indexOf('&env=test'))
if (window.location.href.indexOf('&env=test') > -1) {
    console.log('测试')
        baseURL = "http://api.test.songzizhongyi.com:9998/api";
} else {
    console.log("产线")
        baseURL = "http://api.songguoai.com";
}
export { baseURL };
